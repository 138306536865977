<template>
	<!--用户信息-->
	<mainBox>
		<TSeach :topSearch="topSearch" v-model="seachData" @initSeach="initSeach">
			<template slot='btnSlot'>
				<div class="eventBtn rightBtnGroup">
					<el-button class="itBtn" type="primary" icon="el-icon-search" :loading="tbloading" @click="seach">{{$t("commons.search")}}</el-button><!-- 查询 -->
					<el-button class="itBtn" type="primary" icon="el-icon-plus"  @click="tbRowAdd">{{$t("commons.add")}}</el-button><!-- 添加 -->
				</div>
			</template>
		</TSeach>
		<!--<div class="tbBtns">
			<el-button class="itBtn" type="success" @click="tbRowAdd"  icon="el-icon-plus" size="mini" plain >添加</el-button>
		</div>-->
		<div class="table-body">
			<TTable ref="pointTable" :tbloading='tbloading'  :tableData="tableData" serialNumber :tableTitle="tableTitle" :tbParams="tableParams">
				<template slot='operating' slot-scope="scope">
					<el-button type="text" @click.stop="tbRowEdit(scope.row)" >编辑</el-button>
					<!--<el-button type="text" v-if='scope.row.enterpriseId' @click.stop="tbRowUnbinding(scope.row)" >解绑</el-button>-->
					<el-button type="text" v-if='scope.row.isForbidden' @click.stop="tbRowEnable(scope.row)" >启用</el-button>
					<el-button type="text" v-if='!scope.row.isForbidden' @click.stop="tbRowDisable(scope.row)">禁用</el-button>
				</template>
			</TTable>
		</div>
		<TPagination v-model="tableParams" @initTable="initTable"></TPagination><!-- 分页 -->
		<addOrEdit  :isOpen='addModel.open' @close='closeDialog'  :dataId='String(addModel.editRowId)' />
	</mainBox>
	
</template>

<script>
	import pagination from "@/mixins/TTable/pagination";
	import seachAndTable from "@/mixins/TTable/seachAndTable";
	import TSeach from '@/components/YTable/TSeach.vue';
	import TTable from '@/components/YTable/TTable.vue';
	import TPagination from '@/components/YTable/TPagination.vue';
	import TForm from '@/components/YTable/TForm.vue';
	import addOrEdit from './model/addOrEdit.vue'
	export default {
		mixins: [pagination,seachAndTable],
		components: {TTable,TSeach,TPagination,TForm,addOrEdit},
		data() {
			return {
				addModel:{//新增，编辑的弹框信息
		      		open:false,
		      		editRowId:'',
		      	},
			};
		},
		created() {
			this.initTbConfig();
		},
		mounted() {},
		computed: {},
		methods: {
			initTbConfig(){
				const seachArr = [
			    	{name:'关键词',type:"text",bindKeys:"searchPhrase",defaultValue:"",},
				]
				const tableTitle = [
					{name:'用户名称',prop:"userName"},
					{name:'联系电话',prop:"phone"},
					{name:'性别',prop:"sexStr"},
					{name:'企业名称',prop:"enterpriseName"},
					{name:'员工角色',prop:"employeeRoleName"},
					{name:'来源',prop:"source"},
					{name:'微信号',prop:"wechatNumber"},
					{name:'微信昵称',prop:"wechatName"},
					{name:'备注',prop:"remark"},
					{name:'创建时间',prop:"creationDateStr"},
				    {name:'操作',width:"180",fixed:'right',slotName:"operating",},
				];
				this.topSearch = seachArr;//参数需注入到minxs
				this.tableTitle = tableTitle;
			},
			async initTable(){
				let params = {
					...this.seachData,
					current: this.tableParams.current,
					rowCount: this.tableParams.rowCount,
				}
				console.log('initTable',this.seachData,this.tableParams)
//				delete params.total;
	  			this.tbloading=true;
	  			let res = await this.ApiHttp('/organization/userInfo/page',params);
	  			console.log('initTable',res)
	  			if(res){
	  				res.rows.map(it=>{
	  					if(it.sex==1){
	  						it.sexStr='男'
	  					}else if(it.sex==2){
	  						it.sexStr='女'
	  					}else{
	  						it.sexStr='';
	  					}
	  					it.creationDateStr=this.common.getTimeMmss(it.creationDate)
	  				})
	  				this.tableData = res.rows||[];
					this.tableParams.total = res.total;
					this.tbloading=false;
	  			}
				
			},
			
			
			tbRowAdd(){
				this.addModel={
		      		open:true,
		      		editRowId:'',
		      	};
			},
			tbRowEdit(row){
				this.addModel={
		      		open:true,
		      		editRowId:row.userId,
		      	};
			},
			
			tbRowUnbinding(row){
				this.$confirm('您即将解除该用户与该企业的关系，但不影响该用户已生成的数据，是否确认解绑？', '提示').then(() => {
			        let params={...row};
			        params.enterpriseId=0;
			        params.enterpriseName='';
					this.ApiHttp('/organization/userInfo/update',params,'post').then(res=>{
						if(res){
							this.initTable();
							this.$message({
					            type: 'success',
					            message: '解除成功!'
				          	});
						}
					});
		        }).catch(() => {});
			},
			tbRowDisable(row){
				//点击禁用
				this.$confirm('禁用后，该账号无法正常报单，是否确认禁用？', '提示').then(() => {
		          	let params={...row};
		          	params.isForbidden=true;
					this.disableOrEnable(params);
		        }).catch(() => {});
			},
			tbRowEnable(row){
				//点击启用
				let params={...row};
	          	params.isForbidden=false;
				this.disableOrEnable(params);
			},
			disableOrEnable(params){
				//通过params执行启用或者禁用 isForbidden：1禁用0启用   
				this.ApiHttp('/organization/userInfo/update',params,'post').then(res=>{
					if(res){
						this.initTable();
						this.$message({
				            type: 'success',
				            message: '操作成功'
			          	});
					}
				});
			},
			closeDialog(isInit){
				//关闭弹框
				this.addModel={
		      		open:false,
		      		editRowId:'',
		      	};
		      	if(isInit){
		      		this.initTable();
		      	}
		      	
			},
		},
	};
</script>

<style scoped>
</style>